import * as React from 'react';
import { PageProps } from 'gatsby';

import { BaseLayout } from '@layouts/BaseLayout';
import { ContentBlock } from '@components/PrivacyPolicy/ContentBlock';

const PrivacyPolicy: React.FC<PageProps> = () => (
  <BaseLayout
    seo={{
      title: 'Privacy Policy | Ilona K',
      description: 'Ilona K: Privacy Policy & Terms',
    }}
    hiddenHeader
  >
    <ContentBlock />
  </BaseLayout>
);

export default PrivacyPolicy;
