// extracted by mini-css-extract-plugin
export var heading = "ContentBlock-module--heading--3RpFE";
export var backWrapper = "ContentBlock-module--backWrapper--3e47P";
export var backContainer = "ContentBlock-module--backContainer--1MBqj";
export var back = "ContentBlock-module--back--1isWH";
export var backArrows = "ContentBlock-module--backArrows--ZxsBn";
export var headingContainer = "ContentBlock-module--headingContainer--3adUG";
export var headingDescription = "ContentBlock-module--headingDescription--WHmK5";
export var root = "ContentBlock-module--root--BSyHt";
export var buttonArrow = "ContentBlock-module--buttonArrow--2ppIW";
export var container = "ContentBlock-module--container--2qDCZ";
export var mobile = "ContentBlock-module--mobile--3KJg6";
export var light = "ContentBlock-module--light--1IBV9";
export var dark = "ContentBlock-module--dark--njmIn";